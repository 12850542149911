export default () => {
  if (!document.referrer) {
    return;
  }

  try {
    const previousUrl = new URL(document.referrer);

    if (previousUrl.host != window.location.host) {
      return;
    }

    document
      .querySelectorAll("a[data-js-back-link]")
      .forEach(link => (link.href = document.referrer));
  } catch {}
};
