import "classlist-polyfill";
import "formdata-polyfill";
import "url-search-params-polyfill";
import "url-polyfill";
import "element-matches-polyfill";
import elementClosest from "element-closest";

elementClosest(window);

if (!Element.prototype.toggleAttribute) {
  Element.prototype.toggleAttribute = function(name, force) {
    if (force !== void 0) force = !!force;

    if (this.hasAttribute(name)) {
      if (force) return true;

      this.removeAttribute(name);
      return false;
    }
    if (force === false) return false;

    this.setAttribute(name, "");
    return true;
  };
}

if (typeof NodeList !== "undefined" && NodeList.prototype && !NodeList.prototype.forEach) {
  // Yes, there's really no need for `Object.defineProperty` here
  NodeList.prototype.forEach = Array.prototype.forEach;
  if (typeof Symbol !== "undefined" && Symbol.iterator && !NodeList.prototype[Symbol.iterator]) {
      Object.defineProperty(NodeList.prototype, Symbol.iterator, {
          value: Array.prototype[Symbol.itereator],
          writable: true,
          configurable: true
      });
  }
}