import getMapStyles from "./map_styles";
/**
 * Global data
 */
let map;
let mapExist = false;
let markersIcon;
let markersIconActive;
let markerCircleIcon;
let coordinates = {
  lat: 46.856283,
  lng: -71.4817739
};
let autocomplete;
let acceptGeolocation = false;
let markers = [];
let mapList = [];
let activeFilterServices = [];
let activeFilterBanners = [];
const apiKey = "AIzaSyBrR6wi0aLFjsd78VApOD82gv90tmrt0RM";
const DETAILS_OPEN_CLASS = "station__station-details--open";
const ekoSiteName = "Eko";
const currentSite = window.currentSite ?? "Sprint";
const defaultZoomLevel = 6;
const ekoZoomLevel = 9;

/**
 * Add googlemap api script to the body tag
 * @param url
 * @param callback
 * @param id
 */
const addScript = (url, id, callback) => {
  if (!document.querySelector(`#${id}`)) {
    const script = document.createElement("script");
    // if( callback ) script.onload = callback;
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = () => callback(script);
    document.body.appendChild(script);
  }
};

/**
 * Load map api script
 */
const loadMapsAPI = () => {
  if (document.querySelectorAll("#dufresneMap").length > 0) {
    mapExist = true;
    addScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callBack=initMap`,
      "googleScript",
      initMap
    );
  }
};

const loadGooglePlaces = placesInputId => {
  if (
    document.querySelector(placesInputId) &&
    !document.querySelector("#dufresneMap")
  ) {
    addScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callBack=&callBack=initGooglePlaces`,
      "googleScript",
      () => {
        initGooglePlaces(placesInputId);
      }
    );
  }
};

/**
 * Init map styles
 * @returns {*[]}
 */
/**
 * Init map icons
 */
const setMarkerIcons = () => {
  const colorPrimary = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--primary");

  const sharedMarkerOptions = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z",
    fillColor: colorPrimary,
    fillOpacity: 1,
    strokeColor: colorPrimary,
    strokeWeight: 1,
    anchor: new google.maps.Point(12, 24)
  };

  markersIcon = Object.assign({ scale: 1.5 }, sharedMarkerOptions);

  markersIconActive = Object.assign({ scale: 2.5 }, sharedMarkerOptions);

  markerCircleIcon = {
    path: "M10, 10 M-7.5, 0 a 7.5,7.5 0 1,0 15,0 a 7.5,7.5 0 1,0 -15,0",
    fillColor: "#11baff",
    fillOpacity: 1,
    scale: 1,
    strokeColor: "#11BAFF",
    strokeOpacity: 0.2,
    strokeWeight: 10
  };
};

/**
 * Set all map markers
 * @param station
 */
const setMarker = station => {
  const position = {
    lat: parseFloat(station.station_lat),
    lng: parseFloat(station.station_lng)
  };

  const marker = new google.maps.Marker({
    position: position,
    map: map,
    icon: markersIcon,
    title: station.station_name,
    services: station.station_services,
    stationBanner: station.station_banniere,
    hasSprint: station.has_sprint,
  });

  marker.set("id", station.station_id);

  google.maps.event.addListener(marker, "click", function() {
    resetMarkersIcon();
    marker.setIcon(markersIconActive);

    map.panTo(position);
    openStationDetails(marker.id);
  });

  mapList.push(station);
  markers[station.station_id] = marker;
};

const resetMarkersIcon = () => {
  Object.keys(markers).forEach(markerId => {
    if (markerId !== "1") {
      markers[markerId].setIcon(markersIcon);
    }
  });
};

/**
 * Check if there is visible station
 */
const checkIfHasData = () => {
  const visibleMapListItem = document.querySelectorAll(
    ".js-list-item:not(.hidden)"
  );
  const showSectionNoData = visibleMapListItem.length > 0;

  showNoDataSection(showSectionNoData);
};

/**
 * Show and hide section no data
 * @param showSectionNoData
 * @param apiError
 */
const showNoDataSection = (showSectionNoData, apiError = false) => {
  const noDataSection = document.querySelector(".no-data");
  noDataSection.classList.toggle("hidden", showSectionNoData);

  if (apiError) {
    noDataSection.querySelector(".content-no-data").classList.add("hidden");
    noDataSection.querySelector(".content-error").classList.remove("hidden");
  }
};

/**
 * Init map
 */
const initMap = () => {
  const navBarSearch = document.querySelector(
    ".navbar__search.search-location"
  );
  navBarSearch.classList.add("hidden");

  setMarkerIcons();

  let styleMap = new google.maps.StyledMapType(getMapStyles(), {
    name: "Styled Map"
  });

  map = new google.maps.Map(document.getElementById("dufresneMap"), {
    zoom: currentSite === ekoSiteName ? ekoZoomLevel : defaultZoomLevel,
    center: coordinates,
    mapTypeControl: false,
    scrollwheel: false,
    draggable: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"]
    }
  });

  getGeolocation(false);
  initGooglePlaces("#mapPlacesInput");

  map.mapTypes.set("styled_map", styleMap);
  map.setMapTypeId("styled_map");

  if (window.stationData.length) {
    const mapData = window.stationData;

    mapData.forEach(station => {
      setMarker(station);
    });
  } else {
    showNoDataSection(false, true);
  }

  orderMapList();
};

/**
 * Init google places
 */
const initGooglePlaces = placesInputId => {
  const inputPlaces = document.querySelector(placesInputId);

  autocomplete = new google.maps.places.Autocomplete(inputPlaces);
  autocomplete.setComponentRestrictions({ country: ["ca"] });
  autocomplete.setFields(["address_components", "geometry"]);

  autocomplete.addListener("place_changed", onPlaceChanged);

  if (mapExist) {
    autocomplete.bindTo("bounds", map);
    const placesValidCallback = () => {
      document
        .querySelector(".js-station-submit-icon")
        .classList.toggle("autocomplete-ready", Boolean(inputPlaces.value));
    };

    if (window.query !== "") {
      setAutocompleteFromQuery(inputPlaces, placesValidCallback);
    }

    inputPlaces.addEventListener("keyup", placesValidCallback);

    document
      .querySelector(".station__submit-button")
      .addEventListener("click", () => {
        if (inputPlaces.value) {
          onPlaceChanged();
        }
      });
  } else {
    if (placesInputId === "#mobilePlacesInput") {
      autocomplete.addListener("place_changed", () => {
        inputPlaces.previousElementSibling.classList.add("submit-ready");
      });

      inputPlaces.addEventListener("focus", () => {
        const pacContainer = document.querySelector(".pac-container");
        pacContainer.style.marginTop = "-225px";
        pacContainer.style.height = "175px";
      });
    }
  }
};

/**
 * Set autocomplete from menu request
 */
const setAutocompleteFromQuery = (inputPlaces, onFinish) => {
  var request = {
    query: window.query,
    fields: ["formatted_address", "geometry"]
  };

  const service = new google.maps.places.PlacesService(map);

  service.findPlaceFromQuery(request, function(results, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      autocomplete.set("place", results[0]);
      inputPlaces.value = results[0].formatted_address;
      onFinish();
    }
  });
};

/**
 * Get the place data on input change
 */
const onPlaceChanged = () => {
  const place = autocomplete.getPlace();

  coordinates = {
    lat: place.geometry["location"].lat(),
    lng: place.geometry["location"].lng()
  };

  if (mapExist) {
    setGeolocationMarker();
  }
};

/**
 * set geolocation marker
 */
const setGeolocationMarker = () => {
  if (markers.length > 0) {
    if (markers[1]) {
      markers[1].setMap(null);
    }
  }

  const marker = new google.maps.Marker({
    position: coordinates,
    map: map,
    icon: markerCircleIcon
  });

  marker.set("id", 1);
  markers[1] = marker;

  updateDistance(coordinates);
  orderMapList();
  map.setCenter(coordinates);
};

/**
 * Refresh map
 */
const refreshMap = () => {
  setActiveIconCount();
  filterMapMarkers();
  checkIfHasData();
};

/**
 * try geolocation
 */
const getGeolocation = force => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        coordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        if (force || window.query === "") {
          setGeolocationMarker();
        }

        acceptGeolocation = true;
      },
      function(error) {
        if (error.code === error.PERMISSION_DENIED) {
          document.querySelector(".js-locate-me").disabled = true;
          acceptGeolocation = false;
        }
      }
    );
  }
};

/**
 * Order map list by distance
 */
const orderMapList = () => {
  const list = document.querySelector(".station__list");
  const items = list.querySelectorAll(".js-list-item");
  const sortedList = [...items].sort(function(a, b) {
    const distanceA = parseFloat(a.dataset.distance);
    const distanceB = parseFloat(b.dataset.distance);
    return distanceA < distanceB ? -1 : distanceA > distanceB ? 1 : 0;
  });
  for (let item of sortedList) {
    list.appendChild(item);
  }
};

/**
 * Update distance from geolocation
 * @param coordinates
 */
const updateDistance = coordinates => {
  mapList.forEach((station, i) => {
    const position = {
      lat: parseFloat(station.station_lat),
      lng: parseFloat(station.station_lng)
    };
    mapList[i].distance = setDistance(
      coordinates,
      position,
      station.station_id
    );
  });
};

/**
 * Get and set the distance on load or from geolocation
 * @param coordinates
 * @param markerPosition
 * @param stationId
 * @returns {number}
 */
const setDistance = (coordinates, markerPosition, stationId) => {
  const distance = getDistance(coordinates, markerPosition);
  const distanceString =
    distance > 1000 ? Math.floor(distance / 1000) + " KM" : distance + " M";

  const stationListItem = document.querySelector(
    `.js-list-item[data-station-id="${stationId}"]`
  );
  stationListItem.dataset.distance = distance.toString();
  const sectionDistanceList = stationListItem.querySelector(".distance");
  sectionDistanceList.innerHTML = distanceString;
  sectionDistanceList.classList.remove("hidden");

  const stationDetailItem = document.querySelector(
    `.js-station-details[data-station-id="${stationId}"]`
  );
  stationDetailItem.dataset.distance = distance.toString();
  const sectionDistanceDetail = stationDetailItem.querySelector(".distance");
  sectionDistanceDetail.innerHTML = distanceString;
  sectionDistanceDetail.classList.remove("hidden");

  return distance;
};

const rad = x => {
  return (x * Math.PI) / 180;
};

/**
 * Calculate the distance between 2 positions
 * @param p1
 * @param p2
 * @returns {number}
 */
const getDistance = (p1, p2) => {
  const R = 6371000; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.floor(R * c); // returns the distance in meter
};

/**
 * Show or hide stations details
 * @param id
 * @returns {null|Element}
 */
const getStationDetails = id => {
  const selector = `.js-station-details[data-station-id="${id}"]`;
  const matchingDetailsSection = document.querySelector(selector);

  if (!matchingDetailsSection) {
    console.error("could not find station with id " + id);
    return null;
  }
  return matchingDetailsSection;
};

/**
 * Show station details
 * @param event
 * @param stationButton
 */
const openStationDetails = stationId => {
  const detailsElement = getStationDetails(stationId);

  resetMarkersIcon();
  markers[stationId].setIcon(markersIconActive);
  map.panTo(markers[stationId].getPosition());
  document
    .querySelectorAll("." + DETAILS_OPEN_CLASS)
    .forEach(detailsElement => {
      closeStationDetails(detailsElement.dataset.stationId, { animate: false });
    });

  // To save memory, the detail element is initially `display: none`. To have
  // the slide-in animation, we must do the following steps:
  //
  // First frame:
  //   * Remove the `hidden` attribute to make the element visible
  //   * Add the `no-transition` class to prevent the animation of the element
  //     sliding _out_.
  //
  // On the second frame (the `setTimeout` callback) we:
  //   * Re-enable transitions
  //   * Add the `--open` class to trigger the slide-in. Because we're no
  //     longer `display: none`, the animation will be able to run normally.
  detailsElement.removeAttribute("hidden");
  detailsElement.classList.add("no-transition");

  setTimeout(() => {
    detailsElement.classList.remove("no-transition");
    getStationDetails(stationId).classList.add(DETAILS_OPEN_CLASS);
  });
};

/**
 * Hide station details
 * @param event
 * @param stationButton
 */
const closeStationDetails = (stationId, options) => {
  const detailsElement = getStationDetails(stationId);

  if (options.animate) {
    detailsElement.classList.remove(DETAILS_OPEN_CLASS);
  }

  // After the slide-out animation, we'll put the element in `display: none`
  // to save memory.
  setTimeout(() => {
    if (!options.animate) {
      detailsElement.classList.remove(DETAILS_OPEN_CLASS);
    }

    detailsElement.setAttribute("hidden", "");
  }, 250);

  const listContainer = document.querySelector(".station__list");
  listContainer.scrollTop = 0;
};

/**
 * Toggle filter icon
 * @param event
 * @param serviceIcon
 */
const onServiceIconClick = (event, serviceIcon) => {
  serviceIcon.classList.toggle("active");
  const activeIcon = serviceIcon.dataset.service;
  const index = activeFilterServices.indexOf(activeIcon);

  if (serviceIcon.classList.contains("active")) {
    if (index === -1) {
      activeFilterServices.push(activeIcon);
    }
  } else {
    if (index > -1) {
      activeFilterServices.splice(index, 1);
    }
  }

  refreshMap();
};

const onBannerIconClick = (event, bannerIcon) => {
  const index = activeFilterBanners.indexOf(bannerIcon.name);

  if (bannerIcon.checked) {
    if (index === -1) {
      activeFilterBanners.push(bannerIcon.name);
    }
  } else {
    if (index > -1) {
      activeFilterBanners.splice(index, 1);
    }
  }

  refreshMap();
};

/**
 * Hide or show markers form chosen service filter
 */
const filterMapMarkers = () => {
  if (activeFilterBanners.length > 0) {
    filterMapMarkersFromBannerAndServices();
  } else {
    filterMapMarkersFromServices();
  }
};

const filterMapMarkersFromBannerAndServices = () => {
  Object.keys(markers).forEach(markerId => {
    if (markerId !== "1") {
      let isVisible;

      if (activeFilterBanners.indexOf('Sprint') !== -1) {
        isVisible = markers[markerId].hasSprint;
        if (activeFilterBanners.length > 1) {
          isVisible = isVisible && activeFilterBanners.indexOf(markers[markerId].stationBanner) !== -1;
        }
      } else {
        isVisible = activeFilterBanners.indexOf(markers[markerId].stationBanner) !== -1;
      }

      if (isVisible && activeFilterServices.length > 0) {
        isVisible = activeFilterServices.every(activeService => {
          return markers[markerId].services.indexOf(activeService) !== -1;
        });
      }

      markers[markerId].setVisible(isVisible);
      filterMapList(markerId, isVisible);
    }
  });
};

const filterMapMarkersFromServices = () => {
  Object.keys(markers).forEach(markerId => {
    if (markerId !== "1") {
      const isVisible = activeFilterServices.every(activeService => {
        return markers[markerId].services.indexOf(activeService) !== -1;
      });

      markers[markerId].setVisible(isVisible);
      filterMapList(markerId, isVisible);
    }
  });
};

/**
 * Hide or show listItem and detailItem form chosen service filter
 */
const filterMapList = (id, isVisible) => {
  const stationListItem = document.querySelector(
    `.js-list-item[data-station-id="${id}"]`
  );
  const stationDetailItem = document.querySelector(
    `.js-station-details[data-station-id="${id}"]`
  );

  setStationVisible(stationListItem, isVisible);
  setStationVisible(stationDetailItem, isVisible);
};

/**
 * Hide or show station list item or station details
 * @param element
 * @param isVisible
 */
const setStationVisible = (element, isVisible) => {
  if (isVisible) {
    element.classList.remove("hidden");
  } else {
    element.classList.add("hidden");
  }
};

/**
 * Set active icon count
 */
const setActiveIconCount = () => {
  let displayCount = "";
  const resetButton = document.querySelector(
    ".filter-reset-button.js-remove-filters"
  );

  if (activeFilterServices.length > 0) {
    displayCount = "&nbsp;(" + activeFilterServices.length + ")";
  }

  resetButton.classList.toggle("hidden", activeFilterServices.length === 0);

  document.querySelector(".js-filter-count").innerHTML = displayCount;
};

/**
 * Toogle filters bar
 */
const onStationFilterOpen = () => {
  const filterBar = document.querySelector(".station__filters");
  filterBar.classList.toggle("open");
};

/**
 * Remove all filter and refreshMap
 */
const removeAllFilters = () => {
  const serviceIcons = document.querySelectorAll(
    ".station__filter-service-content"
  );
  serviceIcons.forEach(serviceIcon => {
    serviceIcon.classList.remove("active");
  });

  if (activeFilterBanners.length > 0) {
    const bannerCheckBoxes = document.querySelectorAll(
        ".banner-filter"
    );
    bannerCheckBoxes.forEach(bannerCheckBox => {
      bannerCheckBox.checked = false;
    });
  }

  activeFilterServices = [];
  activeFilterBanners = [];
  refreshMap();
};

/**
 * Build url from coordinates to station location
 * and redirect to google direction map
 * @param event
 * @param link
 */
const onDirectionLinkClick = (event, link) => {
  event.preventDefault();
  const destination = `destination=${link.dataset.latLng}`;
  let googleDirectionLink = `${link.href}${destination}`;

  if (acceptGeolocation) {
    const origin = `origin=${coordinates.lat},${coordinates.lng}`;
    googleDirectionLink = `${link.href}${origin}&${destination}`;
  }

  window.open(googleDirectionLink);
};

/**
 * Init map and add addEventListener
 */
const setupMap = () => {
  loadMapsAPI();

  const openButtons = document.querySelectorAll(
    ".js-list-item[data-station-id]"
  );
  openButtons.forEach(button => {
    const stationId = button.dataset.stationId;
    button.addEventListener("click", () => openStationDetails(stationId));
  });

  const closeButtons = document.querySelectorAll(".js-station-back");
  closeButtons.forEach(button => {
    const stationId = button.dataset.stationId;
    button.addEventListener("click", () =>
      closeStationDetails(stationId, { animate: true })
    );
  });

  const filterButton = document.querySelector(".js-filter-button");
  if (filterButton) {
    filterButton.addEventListener("click", () => {
      onStationFilterOpen();
    });
  }

  const serviceIcons = document.querySelectorAll(
    ".station__filter-service-content"
  );
  serviceIcons.forEach(serviceIcon =>
    serviceIcon.addEventListener("click", event =>
      onServiceIconClick(event, serviceIcon)
    )
  );

  const bannerIcons = document.querySelectorAll(
      ".js-banner-filter"
  );
  if (bannerIcons) {
    bannerIcons.forEach(bannerIcon =>
        bannerIcon.addEventListener("click", event =>
            onBannerIconClick(event, bannerIcon)
        )
    );
  }


  const buttonLocateMe = document.querySelector(".js-locate-me");
  if (buttonLocateMe) {
    buttonLocateMe.addEventListener("click", () => {
      getGeolocation(true);
    });
  }

  const removeFilters = document.querySelectorAll(".js-remove-filters");
  removeFilters.forEach(button => {
    button.addEventListener("click", () => {
      removeAllFilters();
    });
  });

  const directionGoogleLink = document.querySelectorAll(".js-direction-button");
  if (directionGoogleLink) {
    directionGoogleLink.forEach(link =>
      link.addEventListener("click", event => onDirectionLinkClick(event, link))
    );
  }
};

export { setupMap, loadGooglePlaces };
