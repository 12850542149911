let SCROLL_THRESHOLD = 150;
if (matchMedia("(min-width: 1300px")) SCROLL_THRESHOLD = 220;

let _segments = null;
const getSegments = () => {
  if (_segments == null) {
    _segments = [...document.querySelectorAll(".js-timeline-segment")];
  }

  return [..._segments];
};

const onScroll = () => {
  const segments = getSegments();

  let activeIndex = -100;
  segments.forEach((segment, index) => {
    const bounds = segment.getBoundingClientRect();
    const active = bounds.y < SCROLL_THRESHOLD;
    segment.classList.toggle("timeline__list-segment--active", active);
    if (active) activeIndex = index;
  });

  segments.forEach((segment, index) => {
    segment.classList.toggle(
      "timeline__list-segment--upcoming",
      index === activeIndex + 1
    );
  });
};

export default () => {
  if (getSegments().length > 0) {
    document
      .querySelector(".main-content")
      .addEventListener("scroll", () => requestAnimationFrame(onScroll));
    window.addEventListener("scroll", () => requestAnimationFrame(onScroll));
  }
};
