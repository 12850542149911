const externalLinks = document.querySelectorAll("a[data-external-link]");
const container = document.querySelector(".js-external-link-popup");
const continueLink = document.querySelector(".js-external-link-continue");
const cancelButton = document.querySelector(".js-external-link-cancel");
const dialogContainer = document.querySelector(".js-external-link-dialog")

let prevFocus = null;

const showPopup = clickEvent => {
  clickEvent.preventDefault();
  clickEvent.stopPropagation();
  prevFocus = clickEvent.target;
  window.addEventListener("keyup", escapeListener);
  window.addEventListener("click", clickOutsideListener);
  container.removeAttribute("hidden");
  continueLink.setAttribute("href", event.target.getAttribute("href"));
  continueLink.focus();
};

const hidePopup = () => {
  container.setAttribute("hidden", "");
  window.removeEventListener("keyup", escapeListener);
  window.removeEventListener("click", clickOutsideListener);

  if (prevFocus) {
    prevFocus.focus();
  }
};

function escapeListener(event) {
  if (event.key === "Escape") {
    hidePopup();
  }
}

function clickOutsideListener(event) {
  if (!dialogContainer.contains(event.target)) {
    hidePopup();
  }
}

const setupExternalLinks = () => {
  externalLinks.forEach(link => link.addEventListener("click", showPopup));
  cancelButton.addEventListener("click", hidePopup);
};

export default setupExternalLinks;
