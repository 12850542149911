import { loadGooglePlaces } from "./map";

export default () => {
    const HIDDEN_CLASS = "location-popup__container--hidden";
    const locationContainer = document.querySelector(".js-location-popup-container");

    const hide = () => locationContainer.classList.add(HIDDEN_CLASS);
    const show = () => locationContainer.classList.remove(HIDDEN_CLASS);
    const escapeHandler = event => {
        if (event.key === "Escape") {
            hide();
        }
    };

    document
        .querySelectorAll('[data-location-popup="true"]')
        .forEach(openPopup => {
            openPopup.addEventListener("click", () => {
                if(!document.querySelector('#dufresneMap')) {
                    loadGooglePlaces('#desktopPlacesInput');
                    show();
                    window.addEventListener("keyup", escapeHandler);
                }
            });
        });

    document
        .querySelectorAll('[data-location-popup="false"]')
        .forEach(openPopup => {
            openPopup.addEventListener("click", () => {
                locationContainer.classList.add(HIDDEN_CLASS);
                window.removeEventListener("keyup", escapeHandler);
            });
        });
};
