let fileListHtml;
let lisItemElement = "";
let listItemCount = 0;
let inputFileElement = "";
let inputFileContainer = "";
const patternPhone = "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$";

window.onValidCaptcha = () => {
  document
    .querySelector("#submitButton")
    .setAttribute("data-captcha-valid", "");
  checkForm();
};

window.onCaptchaExpired = () => {
  document.querySelector("#submitButton").removeAttribute("data-captcha-valid");
  checkForm();
};

const insertAfter = (referenceNode, newNode, elClass = "") => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);

  if (elClass !== "") {
    newNode.classList.add(elClass);
  }
};

const initCustomDropdowns = () => {
  const dropdownElements = document.querySelectorAll(".custom-dropdown select");

  dropdownElements.forEach((select) => {
    const options = select.querySelectorAll("option");
    const styledSelect = document.createElement("div");
    insertAfter(select, styledSelect, "dropdown-styled");
    styledSelect.innerHTML = options[0].text;
    styledSelect.classList.add("fake-place-holder");

    const optionList = document.createElement("ul");
    insertAfter(styledSelect, optionList, "select-options");
    optionList.classList.add("hidden");

    options.forEach((option) => {
      const listElement = document.createElement("li");
      listElement.setAttribute("rel", option.getAttribute("value"));
      listElement.innerHTML = option.text;
      optionList.appendChild(listElement);
    });

    const listItems = optionList.querySelectorAll("li");

    styledSelect.addEventListener("click", (event) => {
      event.stopPropagation();
      styledSelect.classList.toggle("active");
      optionList.classList.toggle("hidden");
    });

    listItems.forEach((listItem) => {
      listItem.addEventListener("click", (event) => {
        event.stopPropagation();
        styledSelect.innerText = listItem.innerHTML;
        styledSelect.classList.remove("active");
        styledSelect.classList.remove("fake-place-holder");
        select.value = listItem.getAttribute("rel");
        optionList.classList.add("hidden");

        if (select.hasAttribute("required")) {
          checkForm();
        }
      });
    });

    document.addEventListener("click", () => {
      styledSelect.classList.remove("active");
      optionList.classList.add("hidden");
    });
  });
};

const initEventOnHiddenInput = (element) => {
  element.addEventListener("keyup", () => {
    checkForm();
  });
};

const setRequiredHiddenField = (element1, element2) => {
  const labelElement1 = element1.querySelector("label span");
  const labelElement2 = element2.querySelector("label span");
  labelElement1.classList.remove("hidden");
  labelElement2.classList.add("hidden");
  element1.querySelector("input").required = true;
  element2.querySelector("input").required = false;
  initEventOnHiddenInput(element1);
};

const initContactTypeEvent = () => {
  const contactForm = document.querySelector(
    '.contact-form[data-section-id="#contactForm"]'
  );
  if (contactForm) {
    const formGroupContactType = contactForm.querySelector(
      "#formGroupContactType"
    );
    const listItems = formGroupContactType.querySelectorAll(
      ".custom-dropdown ul li"
    );
    const phoneFormGroup = contactForm.querySelector("#inputPhone");
    const emailFormGroup = contactForm.querySelector("#inputEmail");

    listItems.forEach((item) => {
      if (item.getAttribute("rel") !== "hide") {
        item.addEventListener("click", (event) => {
          if (
            item.getAttribute("rel") === "Téléphone" ||
            item.getAttribute("rel") === "Phone"
          ) {
            setRequiredHiddenField(phoneFormGroup, emailFormGroup);
            phoneFormGroup
              .querySelector("input")
              .setAttribute("pattern", patternPhone);
          }

          if (
            item.getAttribute("rel") === "Courriel" ||
            item.getAttribute("rel") === "Email"
          ) {
            setRequiredHiddenField(emailFormGroup, phoneFormGroup);
          }

          checkForm();
        });
      }
    });
  }
};

const validateSubmitButton = (fields) => {
  let formValid = true;

  fields.forEach((field) => {
    if (!field.parentNode.classList.contains("hidden")) {
      if (field.dataset.inputType === "select") {
        if (field.value === "hide") {
          formValid = false;
        }
      } else if (field.dataset.inputType === "file") {
        const inputFiles = document.querySelectorAll(
          '[data-input-type="file"]:not(.origin)'
        );
        if (inputFiles.length === 1) {
          if (inputFiles[0].files.length === 0) {
            formValid = false;
          }
        }
      } else {
        if (field.value.length === 0) {
          formValid = false;
        }
      }
    }
  });

  return formValid;
};

const checkForm = () => {
  const requiredFields = document.querySelectorAll("[required]");
  const requiredClassFields = document.querySelectorAll(
    ".required:not(.origin)"
  );

  const submitButton = document.querySelector("#submitButton");

  const captchaValid = submitButton.hasAttribute("data-captcha-valid");
  const formValid =
    validateSubmitButton(requiredFields) &&
    validateSubmitButton(requiredClassFields) &&
    captchaValid;

  submitButton.disabled = !formValid;
};

const addClickEventOnDeleteFileItem = (deleteButton) => {
  deleteButton.addEventListener("click", () => {
    const inputId = deleteButton.parentNode.dataset.inputId;
    const inputSelector = `input[data-input-id="${inputId}"]:not(.origine)`;
    const inputFile = document.querySelector(inputSelector);
    listItemCount--;
    deleteButton.parentNode.remove();
    inputFile.remove();
    addInputFile();
    checkForm();
  });
};

const handleFileSelect = (e) => {
  if (!e.target.files) return;

  const inputFiles = document.querySelectorAll(
    '[data-input-type="file"]:not(.origin)'
  );

  fileListHtml.innerHTML = "";
  inputFiles.forEach((inputFile, i) => {
    inputFile.dataset.fileCount = inputFile.files.length;
    inputFile.dataset.inputId = i.toString();

    const file = inputFile.files[0];
    const listItem = lisItemElement.cloneNode(true);
    listItem.classList.remove("origin");
    listItem.dataset.inputId = i.toString();
    listItem.querySelector(".file-name").innerHTML = file.name;

    addClickEventOnDeleteFileItem(
      listItem.querySelector(".file-delete-button")
    );
    fileListHtml.appendChild(listItem);
    inputFile.dataset.fileCount = "1";
    listItemCount++;
  });

  addInputFile();
  checkForm();

  fileListHtml.classList.toggle("hidden", fileListHtml.length > 0);
};

const addInputFile = () => {
  const emptyInputFile = document.querySelector(
    'input[data-file-count="0"]:not(.origin)'
  );
  if (!emptyInputFile) {
    const inputFiles = document.querySelectorAll(
      '[data-input-type="file"]:not(.origin)'
    );
    const inputFileItem = inputFileElement.cloneNode(true);
    inputFileItem.dataset.inputId = inputFiles.length.toString();
    inputFileItem.dataset.fileCount = "0";
    inputFileItem.classList.remove("origin");
    inputFileItem.setAttribute(
      "style",
      "z-index:" + inputFiles.length.toString()
    );
    addChangeEventToInputFile(inputFileItem);
    inputFileContainer.appendChild(inputFileItem);
  }
};

const addChangeEventToInputFile = (element) => {
  element.addEventListener("change", handleFileSelect, false);
};

const initFileUploadEvent = () => {
  lisItemElement = document.querySelector("#fileInputItems > li");
  inputFileElement = document.querySelector("#fileInputItems > input");
  inputFileContainer = document.querySelector("#inputFileContainer");

  const fileUpload = document.querySelectorAll('[data-input-type="file"]');
  fileUpload.forEach((fileUploadBtn) => {
    addChangeEventToInputFile(fileUploadBtn);
    fileListHtml = document.querySelector(".file-upload-list");
  });
};

const doFormat = (x, pattern, mask) => {
  let strippedValue = x.replace(/[^0-9]/g, "");
  let chars = strippedValue.split("");
  let count = 0;

  let formatted = "";
  for (let i = 0; i < pattern.length; i++) {
    const c = pattern[i];
    if (chars[count]) {
      if (/\*/.test(c)) {
        formatted += chars[count];
        count++;
      } else {
        formatted += c;
      }
    } else if (mask) {
      if (mask.split("")[i]) formatted += mask.split("")[i];
    }
  }
  return formatted;
};

const initMaskInputs = () => {
  document.querySelectorAll("[data-mask]").forEach(function(e) {
    function format(elem) {
      const val = doFormat(elem.value, elem.getAttribute("data-format"));
      elem.value = doFormat(
        elem.value,
        elem.getAttribute("data-format"),
        elem.getAttribute("data-mask")
      );

      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move("character", val.length);
        range.select();
      } else if (elem.selectionStart) {
        elem.setSelectionRange(val.length, val.length);
      }
    }
    e.addEventListener("keyup", function() {
      format(e);
    });
    format(e);
  });
};

export default () => {
  const form = document.querySelector("form:not(.navbar__search)");
  if (form) {
    const requiredFields = document.querySelectorAll("[required]");
    const submitButton = document.querySelector("#submitButton");
    if (submitButton) {
      submitButton.disabled = true;
    }

    requiredFields.forEach((field) => {
      field.addEventListener("keyup", () => {
        checkForm();
      });
    });
  }

  initCustomDropdowns();
  initFileUploadEvent();
  initContactTypeEvent();
  initMaskInputs();
};
