const FormType = {
  Filter: "filter",
  Link: "link",
};

const RESULTS_ANCHOR = "#js-career-results-anchor";
const CAREER_PREFIX = "career-";
const LOCATION_PREFIX = "location-";

const getFormData = (form) => {
  const data = { titles: [], locations: [], fullTime: false, partTime: false };

  for (const [key, _] of new FormData(form)) {
    if (key.startsWith(CAREER_PREFIX)) {
      data.titles.push(key.substring(CAREER_PREFIX.length));
    } else if (key.startsWith(LOCATION_PREFIX)) {
      data.locations.push(key.substring(LOCATION_PREFIX.length));
    } else {
      data[key] = true;
    }
  }

  return data;
};

const getPostTitle = (rawTitle) => {
  rawTitle = rawTitle
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  if (rawTitle.includes("gerant")) {
    if (rawTitle.includes("assi") || rawTitle.includes("adj")) {
      return "assistant-gerant";
    }
    return "gerant";
  }

  if (rawTitle.includes("caissier") || rawTitle.includes("cassier")) {
    if (rawTitle.includes("soir") || rawTitle.includes("nuit")) {
      return "commis-caissier-de-nuit";
    }
    return "commis-caissier";
  }

  if (rawTitle.includes("prepose")) {
    return "prepose-pret-a-manger";
  }

  if (rawTitle.includes("superv")) {
    return "superviseur";
  }

  console.error("Unable to classify position " + rawTitle);
  return "";
};

const getOfferData = (elem) => {
  return {
    location: elem.dataset.locality,
    title: getPostTitle(elem.dataset.title),
    partTime: elem.hasAttribute("data-part-time"),
    fullTime: elem.hasAttribute("data-full-time"),
  };
};

const offerMatches = (formData, offerData) => {
  if (
    formData.locations.length > 0 &&
    !formData.locations.includes(offerData.location)
  ) {
    return false;
  }

  if (!formData.partTime && offerData.partTime) {
    return false;
  }

  if (!formData.fullTime && offerData.fullTime) {
    return false;
  }

  if (
    formData.titles.length > 0 &&
    !formData.titles.includes(offerData.title)
  ) {
    return false;
  }

  return true;
};

const setVisibility = (formData, elems) => {
  let visibleOffer = false;

  elems.forEach((elem) => {
    const offerData = getOfferData(elem);
    const match = offerMatches(formData, offerData);
    elem.classList.toggle("hidden", !match);

    if (match) visibleOffer = true;
  });

  document
    .querySelector(".js-careers-no-results")
    .classList.toggle("hidden", visibleOffer);
};

const filterOffers = (form) => {
  document
    .querySelector(".js-careers-listing-container")
    .classList.remove("hidden");
  const formData = getFormData(form);

  setVisibility(formData, document.querySelectorAll(".js-offer"));
};

const dropdownTextListener = () => {
  [".js-location-dropdown", ".js-careers-dropdown"].forEach((className) =>
    setDropdownText(className)
  );
};

const setDropdownText = (className) => {
  const toggle = document.querySelector(className);
  const elems = [...toggle.nextElementSibling.querySelectorAll("input")]
    .filter((input) => input.checked)
    .map((input) => input.nextElementSibling.textContent.trim());

  let text = toggle.dataset.placeholder;
  if (elems.length) {
    text = elems.join(", ");
  }

  toggle.querySelector("span").textContent = text;
};

const onLinkChange = (form) => {
  const queryKeys = [];
  for (const [inputName, _] of new FormData(form).entries()) {
    queryKeys.push(inputName);
  }
  const queryString = "filter=" + queryKeys.join(",");

  const anchor = form.querySelector("a");

  let currentLink;
  try {
    currentLink = new URL(anchor.href);
  } catch {
    return;
  }

  const newLink = `${location.origin}${currentLink.pathname}?${queryString}${RESULTS_ANCHOR}`;
  anchor.href = newLink;
};

const hydrateForm = () => {
  let inputChanged = false;

  const params = new URLSearchParams(location.search);
  let filters = [];
  if (params.has("filter")) {
    filters = params.get("filter").split(",");
  }

  for (const filter of filters) {
    const checkbox = document.getElementById(filter);
    if (checkbox) {
      checkbox.checked = true;
      inputChanged = true;
    }
  }

  return inputChanged;
};

const doTheThing = () => {
  const form = document.querySelector(".js-careers-search-form");

  if (!form) return;

  const formType = form.dataset.type.trim();

  form.addEventListener("submit", (event) => {
    if (formType === FormType.Filter) {
      event.preventDefault();
      filterOffers(form);
    }
  });

  form.addEventListener("change", dropdownTextListener);

  if (formType === FormType.Link) {
    form.addEventListener("change", () => onLinkChange(form));
    onLinkChange(form);
  }

  hydrateForm(form);
  dropdownTextListener();
  if (formType === FormType.Filter && location.hash.includes(RESULTS_ANCHOR)) {
    filterOffers(form);
  }

  document.querySelectorAll(".js-post-types-container a").forEach((anchor) => {
    const position = anchor.href.split("#")[1];
    anchor.addEventListener("click", (event) => {
      event.preventDefault();
      document.querySelector(`#career-${position}`).checked = true;
      dropdownTextListener();
      document
        .querySelector("#js-career-listing-anchor")
        .scrollIntoView(true, { behavior: "smooth" });
    });
  });
};

export default doTheThing;
