const toggleSection = sectionId => {
    const sections = document.querySelectorAll('[data-section-id]');
    const sectionSelector = `[data-section-id="${sectionId}"]`;
    const sectionToToggle = document.querySelector(sectionSelector);

    sections.forEach(item => {
        if (item.dataset.sectionId !== sectionId) {
            item.classList.add('hidden');
        }
    });

    sectionToToggle.classList.toggle('hidden');
};

const setupFormSectionListener = () => {
    const anchorSectionPairing = [["#contact-form-anchor", "#contactForm"], ["#phone-form-anchor", "#phoneSection"]];

    for (const [anchor, sectionId] of anchorSectionPairing) {
        document.querySelectorAll(`[href="${anchor}"]`)
            .forEach(elem => {
                elem.addEventListener("click", () => {
                    toggleSection(sectionId)
                })
            })
    }

};

export default () => {
    setupFormSectionListener();
};
