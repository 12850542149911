import {tns} from "tiny-slider/src/tiny-slider";

const setupHeroCarousel = () => {
  const selector = ".js-hero-carousel";

  if (!document.querySelector(selector)) {
    return;
  }

  let slider = tns({
    container: selector,
    items: 1,
    slideBy: "page",
    controls: false,
    navPosition: "bottom",
    speed: 600,
    autoplay: true,
    autoplayButtonOutput: false,
  });
  let stopVideos = document.getElementsByClassName('stop_video_button');
  let playVideos = document.getElementsByClassName('play_video_button');

  slider.events.on('indexChanged', function (info, eventName) {
    if (info.slideItems.item(info.displayIndex).dataset.type === 'image') {
      var customizedFunction = function (info, eventName) {
        for (var i = 0; i < stopVideos.length; i++) {
          stopVideos.item(i).click();
        }
        slider.events.off('transitionEnd', customizedFunction);
      }
      slider.events.on('transitionEnd', customizedFunction);
    }
  });

  if (stopVideos.length === playVideos.length) {
    for (var i = 0; i < stopVideos.length; i++) {
      stopVideos.item(i).setAttribute('data-index', i);
      stopVideos.item(i).addEventListener('click', function (e) {
        slider.play();
        document.getElementById(e.target.dataset.target).pause();
        e.target.style.display = "none";
        playVideos.item(e.target.dataset.index).setAttribute('style', 'display: inline-block');

        var container = document.querySelectorAll('.carousel__inner-container');
        container.forEach(element => {
          unfade(element);
        });
      });
    }

    for (var i = 0; i < playVideos.length; i++) {
      playVideos.item(i).setAttribute('data-index', i);
      playVideos.item(i).addEventListener('click', function (e) {
        slider.pause();
        document.getElementById(e.target.dataset.target).play();
        e.target.style.display = "none";
        stopVideos.item(e.target.dataset.index).setAttribute('style', 'display: inline-block');

        var container = document.querySelectorAll('.carousel__inner-container');
        container.forEach(element => {
          fade(element);
        });

      });
    }
  }

};

function unfade(element) {
  var opacity = 0.1;
  element.style.display = 'block';
  var timer = setInterval(function () {
    if (opacity >= 1) {
      clearInterval(timer);
    }
    element.style.opacity = opacity;
    element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
    opacity += opacity * 0.1;
  }, 10);
}

function fade(element) {
  var opacity = 1;
  var timer = setInterval(function () {
    if (opacity <= 0.1) {
      clearInterval(timer);
      element.style.display = 'none';
    }
    element.style.opacity = opacity;
    element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
    opacity -= opacity * 0.1;
  }, 10);
}

const setupInformationActionSlider = () => {
  const className = ".js-information-action-carousel";
  const minScreenSize = 768;

  if (!document.querySelector(className) || window.innerWidth >= minScreenSize) {
    return;
  }

  tns({
    container: className,
    items: 1,
    slideBy: "page",
    controls: false,
    navPosition: "bottom",
    preventScrollOnTouch: 'auto',
    loop: false,
    responsive: {
      [minScreenSize]: {
        disable: true
      }
    }
  });
};

export default () => {
  setupInformationActionSlider();
  setupHeroCarousel();
}
