const toggleModal = () => {
    document.querySelector('#customModal')
        .classList.toggle('hidden');
};

export default () => {

    const modal = document.querySelector('#customModal');

    if (modal) {
        if(window.submitSuccess) {
            toggleModal();
        }

        const closeButtons = document.querySelectorAll(".js-close-modal");
        closeButtons.forEach(button => button.addEventListener('click', () => {
            toggleModal();
        }));

        window.onclick = function(event) {
            if (event.target === modal) {
                toggleModal();
            }
        }
    }
};
