import "./polyfills";
import LazyLoad from "vanilla-lazyload";

import setupCarousels from "./carousels";
import setupTextInformationToggles from "./information-text";
import { setupMap, loadGooglePlaces } from "./map";
import enableBackLinks from "./back-links";
import setupTimelineListener from "./timeline-scroll";
import setupTabs from "./tabs";
import setupCareersSearch from "./careers-search";
import setupDropdowns from "./dropdown";
import setupModal from "./modal";
import setupContactUs from "./contact-us";
import setupForm from "./form";
import setupCareersPopup from "./careers-popup";
import setupLocationPopup from "./location-popup";
import setupExternalLinks from "./external-link-popup";
import setupInternetExplorerBanner from './internet-explorer-banner';

let placesLoaded = false;
function toggleNavBar() {
  const mobileNavbar = document.getElementById("navbar-mobile");
  if (!placesLoaded && !mobileNavbar.classList.contains("open")) {
    placesLoaded = true;
    loadGooglePlaces('#mobilePlacesInput');
  }

  mobileNavbar.classList.toggle("open");

  document.querySelectorAll(".js-navbar-toggler").forEach(toggler => {
    toggler.classList.toggle("change");
  });
}

document.addEventListener("DOMContentLoaded", () => {
  setupInternetExplorerBanner();
  new LazyLoad({
    threshold: 600,
    container: document.querySelector(".js-main-content")
  });

  setupCarousels();
  setupTextInformationToggles();
  setupMap();
  enableBackLinks();
  setupTimelineListener();
  setupTabs();
  setupCareersSearch();
  setupDropdowns();
  setupModal();
  setupContactUs();
  setupForm();
  setupCareersPopup();
  setupLocationPopup();
  setupExternalLinks();

  const mainContent = document.querySelector(".main-content");

  document
    .querySelectorAll(".js-navbar-toggler")
    .forEach(elem => elem.addEventListener("click", toggleNavBar));

  mainContent.addEventListener("scroll", () => {
    const nav = document.querySelector("#navbar");
    nav.classList.toggle("scrolled", mainContent.scrollTop > 20);
  });

  document.body.classList.remove("no-js");
  document.body.classList.add("js");
  window.setTimeout(
    () => document.body.classList.remove("no-transition"),
    1000
  );
});
