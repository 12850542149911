const toggleDropdown = (elem, force) => {
  if (!elem.matches("[data-dropdown]")) {
    elem = elem.closest("[data-dropdown]");
  }

  if (typeof force !== 'undefined') {
    elem.querySelector("[data-dropdown-menu]").classList.toggle("hidden", !force);
    elem
      .querySelector("[data-dropdown-toggle]")
      .toggleAttribute("data-open", force);
  } else {
    elem.querySelector("[data-dropdown-menu]").classList.toggle("hidden");
    elem
      .querySelector("[data-dropdown-toggle]")
      .toggleAttribute("data-open");
  }
};

const setupDropdowns = () => {
  const dropdowns = document.querySelectorAll("[data-dropdown]");
  if (!dropdowns.length) return;

  dropdowns.forEach(dropdownElem => {
    const toggle = dropdownElem.querySelector("[data-dropdown-toggle]");
    toggleDropdown(toggle, false)

    toggle.addEventListener("click", event => {
      event.stopPropagation();
      event.preventDefault();
      toggleDropdown(toggle)

      dropdowns.forEach(otherDropdown => {
        if (otherDropdown != dropdownElem) {
          toggleDropdown(otherDropdown, false)
        }
      })
    });
  });

  window.addEventListener("click", event => {
    dropdowns.forEach(dropdownElem => {
      if (!dropdownElem.contains(event.target)) {
        toggleDropdown(dropdownElem, false);
      }
    });
  });

  window.addEventListener("keyup", event => {
    if (event.key == "Escape") {
      document
        .querySelectorAll("[data-dropdown-menu]")
        .forEach(elem => toggleDropdown(elem, false));
    }
  });
};

export default setupDropdowns;
