export default () => {
  const HIDDEN_CLASS = "posts-popup__container--hidden";
  const FORM_SELECTOR = ".js-careers-search-form";
  const FORM_ANCHOR_SELECTOR = "#js-career-listing-anchor";
  const NAVBAR_HIDDEN = "hidden";
  const popupContainer = document.querySelector(".js-careers-popup-container");
  const navbarWrapper = document.querySelector("#navbar");

  const hide = () => {
    popupContainer.classList.add(HIDDEN_CLASS);
    navbarWrapper.classList.remove(NAVBAR_HIDDEN);
  };

  const show = () => {
    popupContainer.classList.remove(HIDDEN_CLASS);
    navbarWrapper.classList.add(NAVBAR_HIDDEN);
  };

  const escapeHandler = event => {
    if (event.key === "Escape") {
      hide();
    }
  };

  document
    .querySelectorAll('[data-careers-popup="true"]')
    .forEach(openPopup => {
      openPopup.addEventListener("click", () => {
        const careerForms = document.querySelectorAll(FORM_SELECTOR);
        if (careerForms.length == 1) {
          show();
          window.addEventListener("keyup", escapeHandler);
        } else if (careerForms.length > 1) {
          document
            .querySelector(FORM_ANCHOR_SELECTOR)
            .scrollIntoView({ behavior: "smooth" });
        }
      });
    });

  document
    .querySelectorAll('[data-careers-popup="false"]')
    .forEach(openPopup => {
      openPopup.addEventListener("click", () => {
        hide();
        window.removeEventListener("keyup", escapeHandler);
      });
    });
};
